<template>
  <div>
    <core-page-title />

    <v-container fluid>
      <v-card>
        <v-card-text class="pt-4 text-center">
          <validation-observer ref="observer">
            <v-row>
              <v-col md="3">
                <v-text-field
                  v-model="configurations.LOGIN_HOUR_START"
                  hide-details="auto"
                  outlined
                  dense
                  v-mask="'##:##'"
                  :label="descriptions.LOGIN_HOUR_START"
                  :disabled="loading"
                />
              </v-col>
              <v-col md="3">
                <v-text-field
                  v-model="configurations.LOGIN_HOUR_END"
                  hide-details="auto"
                  outlined
                  dense
                  v-mask="'##:##'"
                  :label="descriptions.LOGIN_HOUR_END"
                  :disabled="loading"
                />
              </v-col>
            </v-row>
          </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            :disabled="loading"
            :loading="loadingSave"
            @click="save"
          >
            <v-icon
              v-text="'mdi-content-save'"
              left
            />
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>  
    </v-container> 
  </div>
</template>

<script>
  import configurationsApi from '@/api/configurations'
  
  export default {

    data () {
      return {
        loading: false,
        loadingSave: false,
        configurations: {
          LOGIN_HOUR_START: null,
          LOGIN_HOUR_END: null,
        },
        descriptions: {}
      }
    },

    mounted () {
      this.load()
    },

    methods: {
      async load () {
        try {
          this.loading = true

          const responseHourStart = await configurationsApi.get('LOGIN_HOUR_START')
          const responseHourEnd = await configurationsApi.get('LOGIN_HOUR_END')

          this.configurations.LOGIN_HOUR_START = responseHourStart.data.configuration.value
          this.descriptions.LOGIN_HOUR_START = responseHourStart.data.configuration.description

          this.configurations.LOGIN_HOUR_END = responseHourEnd.data.configuration.value
          this.descriptions.LOGIN_HOUR_END = responseHourEnd.data.configuration.description
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        try {
          this.loadingSave = true

          this.$refs.observer.reset()

          await configurationsApi.update('LOGIN_HOUR_START', { value: this.configurations.LOGIN_HOUR_START })
          await configurationsApi.update('LOGIN_HOUR_END', { value: this.configurations.LOGIN_HOUR_END})
          
          this.$snackbar.show({
            color: 'success',
            message: this.sectorId ? this.$messages._('update_success') : this.$messages._('new_success')
          })
        } catch (e) {
          console.log(e)
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },
    }

  }
</script>
