import axios from '@/plugins/axios'

export default {
  async get(key) {
    return await axios.get(`configurations/${key}`);
  },

  async update(key, data) {
    return await axios.put(`configurations/${key}`, data);
  }
};
